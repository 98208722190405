import { createRouter, createWebHistory } from 'vue-router'

// import HomePage from '@/pages/HomePage.vue'
// import GenesisList from '@/pages/GenesisList.vue'
import RefundPage from '@/pages/ActivityPage.vue'
// import PassportPage from '@/pages/PassportPage.vue'
// import RedirectPage from '@/pages/RedirectPage'
// import LogoutPage from '@/pages/LogoutPage'

const routes = [
  { path: '/', name: 'Home', component: RefundPage },
  // { path: '/preGenesis', name: 'GenesisList', component: GenesisList },
  // { path: '/refund', name: 'RefundPage', component: HomePage },
  // { path: '/passport', name: 'PassportPage', component: PassportPage },
  // { path: '/redirect', name: 'RedirectPage', component: RedirectPage },
  // { path: '/logout', name: 'LogoutPage', component: LogoutPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
